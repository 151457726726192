import { GetServerDataReturn } from 'gatsby';
import React from 'react';

import TechnologyPage from '../components/TechnologyPage';
import { GLOBAL_QUERY } from '../constants';
import { TPageProp } from '../types';
import { IGlobalServerRuntimeData } from '../types/strapi/global';
import { ITechnologyPage } from '../types/strapi/technology';

type TProp = TPageProp<IServerRuntimeData>;

const Technology: React.FC<TProp> = ({ serverData }) => {
    return (
        <TechnologyPage data={serverData} />
    );
};

export default Technology;

const query = `
  query TechnologyPage ($locale: String) {
    ${GLOBAL_QUERY}
    technologyPage(locale: $locale) {
      seo {
        metaTitle
        metaDescription
        shareImage {
          url
        }
      }
      technologyBanner {
        title
        description
        image {
          desktopSmall {
            url
          }
          desktopNormal {
            url
          }
          mobile {
            url
          }
          tablet {
            url
          }
        }
      }
      principles {
        title
        description
      }
      transportTypeTitle
      transportTypeDescription
      transportTypeCard {
        title
        description
        link
        image {
          desktopSmall {
            url
          }
          desktopNormal {
            url
          }
          mobile {
            url
          }
          tablet {
            url
          }
        }
      }
      sensorsBlock {
        sensors {
          code
          title
          description
          image {
            desktopSmall {
              url
            }
            desktopNormal {
              url
            }
            mobile {
              url
            }
            tablet {
              url
            }
          }
        }
        image {
          desktopSmall {
            url
          }
          desktopNormal {
            url
          }
          mobile {
            url
          }
          tablet {
            url
          }
        }
      }
      driveAlgorithmTitle
      driveAlgorithmDescription
      driveAlgorithmVideoAccordeon {
        code
        title
        description
        desktopSmall {
          sources {
            url
          }
          preview {
            url
          }
        }
        mobile {
          sources {
            url
          }
          preview {
            url
          }
        }
      }
      aboutTestingTitle
      aboutTestingDescription
      aboutTestingItem {
        title
        description
        preview {
          url
        }
        desktopNormal {
          sources {
            url
          }
          preview {
            url
          }
        }
        mobile {
          sources {
            url
          }
          preview {
            url
          }
        }
      }
      footerText
    }
  }
`;

interface IServerRuntimeData extends IGlobalServerRuntimeData {
    technologyPage: ITechnologyPage
}

export async function getServerData(): Promise<GetServerDataReturn<IServerRuntimeData>> {
    try {
        const variables = JSON.stringify({
            locale: 'ru'
        });
        const response = await fetch(
            `${process.env.GATSBY_API_URL}/graphql?query=${query}&variables=${variables}`
        );

        const data: { data: IServerRuntimeData } = await response.json();

        return {
            props: {
                navPanel      : data.data.navPanel,
                global        : data.data.global,
                respondForm   : data.data.respondForm,
                footer        : data.data.footer,
                technologyPage: data.data.technologyPage
            }
        };
    } catch(error) {
        return {
            status: 500
        };
    }
}
